<template>
  <Main :levelOne="levelOne"/>
</template>

<script>
import Main from "../Main.vue"

export default {
  name: "Level One",
  components: {
    Main
  },
  props: {
    levelOne: String
  },
}
</script>
