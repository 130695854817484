<template>
  <Header />
  <router-view />
  <Footer />
</template>

<script>
import axios from 'axios'
import router from '@/router'
import Header from './components/Header'
import Footer from './components/Footer'

export default {
  components: {
    Header,
    Footer
  },
  methods: {
    isMobile() {
      if ( window.matchMedia('(max-width: 1023px)').matches ) {
        this.$store.dispatch('setIsMobile', true);
      } else {
        this.$store.dispatch('setIsMobile', false);
      }
    }
  },
  created() {
    axios.get('https://data.ecosystemsolutionspartners.com/partners.json')
      .then(response => {
        let data   = response.data;

        this.$store.dispatch('setData', data);
        this.$store.dispatch('setIsLoading', false);
        this.$store.dispatch('setRouteObject', this.$route);
        this.$store.dispatch('setLevel', this.$route.meta.menuLevel);

        if ( this.$route.query.grid === 'showmethegrid' ) {
          this.$store.dispatch('setShowGrid', true);
        }

        router.beforeEach((to, from, next) => {
          this.$store.dispatch('setRouteObject', to);
          this.$store.dispatch('setLevel', to.meta.menuLevel);
          next();
        })
    }).catch( err => {
      console.log(err);
    });

    window.addEventListener('DOMContentLoaded', this.isMobile);
    window.addEventListener('resize', this.isMobile);
  }
}
</script>

<style>
  #app {
    @apply flex flex-col h-full min-h-screen;
  }
  a.router-link-active {
    @apply text-white bg-black;
  }
</style>
