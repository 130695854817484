import { createStore } from 'vuex'

export default createStore({
  state: {
    data: '',
    isLoading: true,
    isMobile: false,
    routeObject: null,
    menuItems: null,
    partners: null,
    showGrid: false,
    gridPinX: null,
    gridPinY: null,
  },
  getters: {
    getIsLoading(state) {
      return state.isLoading;
    },
    getIsMobile(state) {
      return state.isMobile;
    },
    getMenuItems(state) {
      return state.menuItems;
    },
    getRouteObject(state) {
      return state.routeObject;
    },
    getRouteName(state) {
      return state.routeObject.name;
    },
    getRouteNameAsSlug(state) {
      return state.routeObject.name.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
    },
    getPartners(state) {
      return state.partners;
    },
    getShowGrid(state) {
      return state.showGrid;
    },
    getGridPinX(state) {
      return state.gridPinX;
    },
    getGridPinY(state) {
      return state.gridPinY;
    }
  },
  mutations: {
    setData(state, payload) {
      state.data = payload;
    },
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
    setIsMobile(state, payload) {
      state.isMobile = payload;
    },
    setRouteObject(state, payload) {
      state.routeObject = payload;
    },
    setLevel(state, payload) {
      /* Payload is equal to the menuLevel set on each route */
      switch (payload) {
        case 'home':
          /* Set partners to null as the home page doesn't show partners */
          state.partners  = null
          /* Set menu objects using continent data */
          state.menuItems = state.data.map(continent => {
            let menuItemObject = {
              to: continent.name, 
              name: continent.name,
              disabled: false
            }

            if ( continent.disabled ) {
              menuItemObject.disabled = true;
            }

            return menuItemObject;
          });
          break;
        default:
          state.data.forEach(continent => {
            /* Check if the current component name (set on each level in router) matches the route name */
            if ( continent.name === state.routeObject.name || 
                 continent.name + ' Level One' === state.routeObject.name || 
                 continent.name + ' Level Two' === state.routeObject.name 
            ) {
              /* If the current continent object contains a partners key or is disabled */
              if ( Object.prototype.hasOwnProperty.call(continent, 'partners') || continent.disabled ) {
                /* Set the partners to the continents partners array */
                state.partners  = continent.partners;
                /* Set the menuItems to just the current continents name */
                state.menuItems = [{ to: continent.name, name: continent.name }];
              }
              /* If the continent object doesn't contain a partners key */ 
              else {
                /* Set the partners to be null */
                state.partners  = null;
                /* Set the menuItems to the name of each level one stored in the continents level one array */
                state.menuItems = continent.level_one.map(levelOne => {
                  let menuItemObject = {
                    to: continent.name, 
                    name: levelOne.name, 
                    levelOne: levelOne.name
                  }

                  if ( Object.prototype.hasOwnProperty.call(levelOne, 'level_two') ) {
                    menuItemObject['level_two'] = levelOne.level_two.map(levelTwo => levelTwo.name).sort();
                  }

                  return menuItemObject;
                });

                /* Loop over the continents level one array */
                continent.level_one.forEach(levelOne => {
                  /* If the levelOne name (converted to slug case) is equal to the current routes levelOne param */
                  if ( levelOne.name.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'') === state.routeObject.params.levelOne ) {
                    /* If the current levelOne object contains a partners key */
                    if ( Object.prototype.hasOwnProperty.call(levelOne, 'partners') ) {
                      /* Set the partners to the levelOne partners array */
                      state.partners = levelOne.partners
                    } 
                    /* If the levelOne object doesn't contain a partners key */ 
                    else {
                      /* Loop over the levelOne level_two array */
                      levelOne.level_two.forEach(levelTwo => {
                        /* If the levelOne name (converted to slug case) is equal to the current routes levelOne param & the levelTwo name (converted to slug case) is equal to the current routes levelTwo param */
                        if ( levelOne.name.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'') === state.routeObject.params.levelOne && levelTwo.name.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'') === state.routeObject.params.levelTwo ) {
                          /* If the current levelTwo object contains a partners key */
                          if ( Object.prototype.hasOwnProperty.call(levelTwo, 'partners') ) {
                            /* Set the partners to the levelTwo partners array */
                            state.partners = levelTwo.partners
                          }
                        }
                      });
                    }
                  }
                });
              }
            }
          });
          break;
      }
    },
    setShowGrid(state, payload) {
      state.showGrid = payload;
    },
    setGridPinX(state, payload) {
      state.gridPinX = payload;
    },
    setGridPinY(state, payload) {
      state.gridPinY = payload;
    }
  },
  actions: {
    setData(state, payload) {
      state.commit('setData', payload);
    },
    setIsLoading(state, payload) {
      state.commit('setIsLoading', payload);
    },
    setIsMobile(state, payload) {
      state.commit('setIsMobile', payload);
    },
    setRouteObject(state, payload) {
      state.commit('setRouteObject', payload);
    },
    setRouteLevelOne(state, payload) {
      state.commit('setRouteLevelOne', payload);
    },
    setLevel(state, payload) {
      state.commit('setLevel', payload);
    },
    setShowGrid(state, payload) {
      state.commit('setShowGrid', payload);
    },
    setGridPinX(state, payload) {
      state.commit('setGridPinX', payload);
    },
    setGridPinY(state, payload) {
      state.commit('setGridPinY', payload);
    }
  }
})
