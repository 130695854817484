<template>
  <div class="absolute top-0 left-0 flex justify-center items-center w-full h-full text-32 text-white bg-black/40 z-50">
    <img class="w-12 h-12 animate-spin-loading" src="@/assets/loading-circle.svg" alt="">
  </div>
</template>

<script>
export default {
  name: 'Loading',
}
</script>