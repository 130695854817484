import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style.css'

const app = createApp(App)
    .use(store)
    .use(router)

/*--------------
      Maps 
--------------*/
import WorldMap from './components/maps/WorldMap.vue'
app.component('home', WorldMap)

import Africa from './components/maps/africa/All.vue'
app.component('africa', Africa)

import AsiaPacific from './components/maps/asia-pacific/All.vue'
app.component('asia-pacific', AsiaPacific)

import Europe from './components/maps/europe/All.vue'
app.component('europe', Europe)
import EasternEurope from './components/maps/europe/EasternEurope.vue'
app.component('eastern-europe', EasternEurope)
import NorthernEurope from './components/maps/europe/NorthernEurope.vue'
app.component('northern-europe', NorthernEurope)
import SouthernEurope from './components/maps/europe/SouthernEurope.vue'
app.component('southern-europe', SouthernEurope)
import WesternEurope from './components/maps/europe/WesternEurope.vue'
app.component('western-europe', WesternEurope)
import UkIreland from './components/maps/europe/UkIreland.vue'
app.component('uk-and-ireland', UkIreland)

import LatinAmericaAndCaribbean from './components/maps/latin-america-and-caribbean/All.vue'
app.component('latin-america-and-caribbean', LatinAmericaAndCaribbean)

import MiddleEast from './components/maps/middle-east/All.vue'
app.component('middle-east', MiddleEast)

import NorthAmerica from './components/maps/north-america/All.vue'
app.component('north-america', NorthAmerica)
import Canada from './components/maps/north-america/canada/All.vue'
app.component('canada', Canada)
import Alberta from './components/maps/north-america/canada/states/Alberta.vue'
app.component('canada-alberta', Alberta)
import BritishColumbia from './components/maps/north-america/canada/states/BritishColumbia.vue'
app.component('canada-british-columbia', BritishColumbia)
import Manitoba from './components/maps/north-america/canada/states/Manitoba.vue'
app.component('canada-manitoba', Manitoba)
import NewBrunswick from './components/maps/north-america/canada/states/NewBrunswick.vue'
app.component('canada-new-brunswick', NewBrunswick)
import NewfoundlandAndLabrador from './components/maps/north-america/canada/states/NewfoundlandAndLabrador.vue'
app.component('canada-newfoundland-and-labrador', NewfoundlandAndLabrador)
import NorthwestTerritories from './components/maps/north-america/canada/states/NorthwestTerritories.vue'
app.component('canada-northwest-territories', NorthwestTerritories)
import NovaScotia from './components/maps/north-america/canada/states/NovaScotia.vue'
app.component('canada-nova-scotia', NovaScotia)
import Nunavut from './components/maps/north-america/canada/states/Nunavut.vue'
app.component('canada-nunavut', Nunavut)
import Ontario from './components/maps/north-america/canada/states/Ontario.vue'
app.component('canada-ontario', Ontario)
import PrinceEdwardIsland from './components/maps/north-america/canada/states/PrinceEdwardIsland.vue'
app.component('canada-prince-edward-island', PrinceEdwardIsland)
import Quebec from './components/maps/north-america/canada/states/Quebec.vue'
app.component('canada-quebec', Quebec)
import Saskatchewan from './components/maps/north-america/canada/states/Saskatchewan.vue'
app.component('canada-saskatchewan', Saskatchewan)
import Yukon from './components/maps/north-america/canada/states/Yukon.vue'
app.component('canada-yukon', Yukon)
import UnitedStates from './components/maps/north-america/united-states/All.vue'
app.component('united-states', UnitedStates)
import AK from './components/maps/north-america/united-states/states/AK.vue'
app.component('united-states-ak', AK)
import AL from './components/maps/north-america/united-states/states/AL.vue'
app.component('united-states-al', AL)
import AR from './components/maps/north-america/united-states/states/AR.vue'
app.component('united-states-ar', AR)
import AZ from './components/maps/north-america/united-states/states/AZ.vue'
app.component('united-states-az', AZ)
import CA from './components/maps/north-america/united-states/states/CA.vue'
app.component('united-states-ca', CA)
import CO from './components/maps/north-america/united-states/states/CO.vue'
app.component('united-states-co', CO)
import CT from './components/maps/north-america/united-states/states/CT.vue'
app.component('united-states-ct', CT)
import DC from './components/maps/north-america/united-states/states/DC.vue'
app.component('united-states-dc', DC)
import DE from './components/maps/north-america/united-states/states/DE.vue'
app.component('united-states-de', DE)
import FL from './components/maps/north-america/united-states/states/FL.vue'
app.component('united-states-fl', FL)
import GA from './components/maps/north-america/united-states/states/GA.vue'
app.component('united-states-ga', GA)
import HI from './components/maps/north-america/united-states/states/HI.vue'
app.component('united-states-hi', HI)
import IA from './components/maps/north-america/united-states/states/IA.vue'
app.component('united-states-ia', IA)
import ID from './components/maps/north-america/united-states/states/ID.vue'
app.component('united-states-id', ID)
import IL from './components/maps/north-america/united-states/states/IL.vue'
app.component('united-states-il', IL)
import IN from './components/maps/north-america/united-states/states/IN.vue'
app.component('united-states-in', IN)
import KS from './components/maps/north-america/united-states/states/KS.vue'
app.component('united-states-ks', KS)
import KY from './components/maps/north-america/united-states/states/KY.vue'
app.component('united-states-ky', KY)
import LA from './components/maps/north-america/united-states/states/LA.vue'
app.component('united-states-la', LA)
import MA from './components/maps/north-america/united-states/states/MA.vue'
app.component('united-states-ma', MA)
import MD from './components/maps/north-america/united-states/states/MD.vue'
app.component('united-states-md', MD)
import ME from './components/maps/north-america/united-states/states/ME.vue'
app.component('united-states-me', ME)
import MI from './components/maps/north-america/united-states/states/MI.vue'
app.component('united-states-mi', MI)
import MN from './components/maps/north-america/united-states/states/MN.vue'
app.component('united-states-mn', MN)
import MO from './components/maps/north-america/united-states/states/MO.vue'
app.component('united-states-mo', MO)
import MS from './components/maps/north-america/united-states/states/MS.vue'
app.component('united-states-ms', MS)
import MT from './components/maps/north-america/united-states/states/MT.vue'
app.component('united-states-mt', MT)
import NC from './components/maps/north-america/united-states/states/NC.vue'
app.component('united-states-nc', NC)
import ND from './components/maps/north-america/united-states/states/ND.vue'
app.component('united-states-nd', ND)
import NE from './components/maps/north-america/united-states/states/NE.vue'
app.component('united-states-ne', NE)
import NH from './components/maps/north-america/united-states/states/NH.vue'
app.component('united-states-nh', NH)
import NJ from './components/maps/north-america/united-states/states/NJ.vue'
app.component('united-states-nj', NJ)
import NM from './components/maps/north-america/united-states/states/NM.vue'
app.component('united-states-nm', NM)
import NV from './components/maps/north-america/united-states/states/NV.vue'
app.component('united-states-nv', NV)
import NY from './components/maps/north-america/united-states/states/NY.vue'
app.component('united-states-ny', NY)
import OH from './components/maps/north-america/united-states/states/OH.vue'
app.component('united-states-oh', OH)
import OK from './components/maps/north-america/united-states/states/OK.vue'
app.component('united-states-ok', OK)
import OR from './components/maps/north-america/united-states/states/OR.vue'
app.component('united-states-or', OR)
import PA from './components/maps/north-america/united-states/states/PA.vue'
app.component('united-states-pa', PA)
import RI from './components/maps/north-america/united-states/states/RI.vue'
app.component('united-states-ri', RI)
import SC from './components/maps/north-america/united-states/states/SC.vue'
app.component('united-states-sc', SC)
import SD from './components/maps/north-america/united-states/states/SD.vue'
app.component('united-states-sd', SD)
import TN from './components/maps/north-america/united-states/states/TN.vue'
app.component('united-states-tn', TN)
import TX from './components/maps/north-america/united-states/states/TX.vue'
app.component('united-states-tx', TX)
import UT from './components/maps/north-america/united-states/states/UT.vue'
app.component('united-states-ut', UT)
import VA from './components/maps/north-america/united-states/states/VA.vue'
app.component('united-states-va', VA)
import VT from './components/maps/north-america/united-states/states/VT.vue'
app.component('united-states-vt', VT)
import WA from './components/maps/north-america/united-states/states/WA.vue'
app.component('united-states-wa', WA)
import WI from './components/maps/north-america/united-states/states/WI.vue'
app.component('united-states-wi', WI)
import WV from './components/maps/north-america/united-states/states/WV.vue'
app.component('united-states-wv', WV)
import WY from './components/maps/north-america/united-states/states/WY.vue'
app.component('united-states-wy', WY)

app.mount('#app')
