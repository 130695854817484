<template>
  <footer class="bg-black text-center px-4 py-6 md:py-10 lg:px-[1.625em] lg:py-[3em]">
    <small class="text-10 lg:text-[1em] xl:text-[0.75em] text-white">MOTOROLA, MOTO, MOTOROLA SOLUTIONS and the Stylized M Logo are trademarks or registered trademarks of Motorola Trademark Holding, LLC and are used under license. All other trademarks are the property of their respective owners.</small>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
