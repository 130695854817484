<template>
  <li class="relative z-20 text-white lg:absolute lg:transform lg:-translate-x-1/2 lg:-translate-y-full lg:p-0" 
      :class="{ 'pointer-events-none': getShowGrid, 'pointer-events-auto': !getShowGrid }" 
      :style="{ 
        'top': partner.pin_Y, 
        'left': partner.pin_X
      }"
  >
    <button :id="`mapPin__toggle--${id}`" class="mapPin__toggle relative flex items-center w-full text-white lg:bg-transparent lg:rounded-none">
      <MapPin :color="setPinColor(partner.approved)" class="flex-shrink-0 w-5 filter drop-shadow-lg lg:w-[2em] lg:origin-bottom lg:transform lg:scale-100 lg:transition-transform lg:duration-200 lg:ease-out" />
      <span class="font-bold block text-16 text-left ml-2 lg:hidden">
        {{ partner.name }}
      </span>
    </button>

    <div :id="`partner--${id}`" class="hidden">
      <div class="block lg:hidden absolute top-0 right-0">
        <button class="trigger text-white leading-none block bg-black rounded-bl-md p-2">Close</button>
      </div>
      <div class="flex-shrink-0 w-1/3 pb-8">
        <img :src="isApproved(partner.approved)" alt="">
      </div>
      <div class="w-2/3 pl-[1em] xs:pl-[2em] lg:pl-[1em]">
        <div class="font-bold text-[1.4em] xs:text-[1.6em] text-primary capital leading-none mb-[0.4em]">
          {{ partner.name }}
        </div>
        <address v-if="partner.address || partner.phone || partner.website" class="text-[0.9375em] leading-tight not-italic space-y-[1.125em]">
          <div v-if="partner.address" v-html="formatAddress(partner.address)"></div>
          <span class="block">
            Phone: <a v-if="partner.phone" class="text-primary uppercase" :href="`tel:${partner.phone}`">{{ partner.phone }}</a>
          </span>
          <div v-if="partner.website" class="inline-block w-max">
            <a class="inline-block font-bold text-[1.125em] text-white bg-primary rounded-[0.25em] px-[2em] py-[0.35em] transition-colors duration-200 ease-in-out sm:text-[1.25em] hover:bg-black" :href="`${partner.website}`" target="_blank">Find out more</a>
            <small class="block text-center mt-[0.75em]">This links to an external site</small>
          </div>
        </address>
      </div>
    </div>
  </li>
</template>

<script>
import MapPin from './MapPin'
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default {
  name: 'Item',
  components: {
    MapPin
  },
  props: {
    partner: Object,
    id: Number
  },
  methods: {
    getIsMobile() {
      return this.$store.getters.getIsMobile;
    },
    isApproved(approved) {
      if ( approved == 1 ) {
        return require('../../assets/badge-partner.png');
      }

      return require('../../assets/badge-non-partner.png');
    },
    formatAddress(address) {
      return address.split(', ').join(',<br>')
    },
    setPinColor(approved) {
      return approved ? '#000' : '#808080';
    }
  },
  computed: {
    getShowGrid() {
      return this.$store.getters.getShowGrid;
    }
  },
  mounted() {
    const mapPin = document.getElementById(`mapPin__toggle--${this.id}`);
    const template = document.getElementById(`partner--${this.id}`);

    tippy(mapPin, {
      content: template.innerHTML,
      allowHTML: true,
      interactive: true,
      placement: 'right-end',
      appendTo: document.body,
      trigger: 'click',
      maxWidth: '25em',
      onMount(instance) {
        instance.popper.addEventListener('click', (event) => {
          if ( event.target === instance.popper || event.target === instance.popper.querySelector('.trigger') ) {
            instance.hide();
          }
        });
      }
    });
  }
}
</script>

<style>
  .tippy-box {
    @apply text-black bg-transparent shadow-lg;
  }

  .tippy-content {
    @apply relative flex flex-wrap bg-white rounded-[0.5em] overflow-hidden p-4 pt-10 lg:p-[1em] lg:pt-[1em];
  }

  .tippy-arrow {
    @apply hidden lg:block text-white w-[1em] h-[1em] z-10;
  }

  @media (max-width: 1023px) {
    div[data-tippy-root][id*="tippy-"] {
      @apply flex justify-center items-center max-w-none bg-black/30;

      position: fixed !important;
      inset: 0 !important;
      transform: unset !important;
      padding: 0.5rem;
    }

    .tippy-box {
      max-width: 32rem !important;
    }
  }

  @screen lg {
    .mapPin__toggle[aria-expanded="false"] svg {
      @apply hover:scale-125 active:scale-110;
    }

    .mapPin__toggle::before,
    .mapPin__toggle::after {
      @apply absolute top-[-50%] left-[-75%] z-[-1] w-[250%] border border-white rounded-full opacity-0 pointer-events-none pt-[250%];
      content: "";
    }

    .mapPin__toggle[aria-expanded="true"]::before,
    .mapPin__toggle[aria-expanded="true"]::after {
      opacity: 0 !important;
      animation-play-state: paused;
    }

    .mapPin--pulse::before,
    .mapPin--pulse::after {
      animation: pulse 2000ms linear infinite;
      animation-delay: 0;
    }

    .mapPin--pulse::after {
      animation-delay: 1000ms;
    }

    @keyframes pulse {
      0% {
        transform: scale(0.6);
        opacity: 0;
      }
      70% {
        opacity: 1;
      }
      100% {
        transform: scale(1.1);
        opacity: 0;
      }
    }

    .tippy-box {
      font-size: 0.625vw;
    }

    .tippy-box[data-placement^=left]>.tippy-arrow:before {
      right: -0.5em;
      border-width: 0.5em 0 0.5em 0.5em;
    }

    .tippy-box[data-placement^=right]>.tippy-arrow:before {
      left: -0.5em !important;
      border-width: 0.5em 0.5em 0.5em 0 !important;
    }
  }
</style>