<template>
  <div v-if="!getIsLoading">
    <h2 class="font-bold text-24 lg:text-[2em] text-white uppercase mb-[0.25em]" v-text="setMenuTitle"></h2>

    <nav class="lg:w-[25em]">
      <ul class="text-20 space-y-3 lg:text-[1.625em] lg:space-y-[0.75em]">
        <li class="text-white" v-for="(item, index) in getMenuItems" :key="index">
          <router-link
            v-if ="!item.disabled"
            class="font-bold relative text-center leading-tight block rounded-[0.25em] p-[0.5em] lg:before:absolute lg:before:inset-[-0.1em] lg:before:border-[0.2em] lg:before:rounded-[inherit] lg:before:opacity-0 lg:before:transition-opacity lg:before:duration-200 lg:before:ease-out hover:lg:before:opacity-100"
            :class="isHome ? 'text-white bg-primary lg:before:border-white' : 'text-primary bg-white lg:before:border-primary'"
            :to="setToObject(item.to, item.levelOne, item.levelTwo)"
          >
            {{ item.name }}
          </router-link>
          <div v-else class="font-bold text-center leading-tight block bg-grey opacity-50 rounded-[0.25em] cursor-not-allowed p-[0.5em]">
            {{ item.name }}
          </div>

          <div v-if="item.level_two && this.$store.getters.getIsMobile && this.$store.getters.getRouteObject.params.levelOne == convertToSlug(item.name)" class="levelOne__select mt-2">
            <select @change="goToLevelTwo" class="text-18 text-black leading-none w-full rounded-[0.25em] p-2.5">
              <option disabled selected value>Select a State</option>
              <option v-for="(levelTwo, index) in item.level_two" :key="index" :value="levelTwo">{{ levelTwo }}</option>
            </select>
          </div>
        </li>

        <li v-if="!isHome" class="back">
          <span class="hidden font-bold text-18 text-white block">Partners</span>
          <button  
            class="font-bold relative text-20 text-white text-center leading-tight flex justify-center items-center w-full bg-gray-500 rounded-[0.25em] p-[0.5em] lg:before:absolute lg:before:inset-[-0.1em] lg:before:border-[0.2em] before:border-white before:rounded-[inherit] before:opacity-0 before:transition-opacity before:duration-200 before:ease-out hover:before:opacity-100 lg:text-[1em]"
            @click="setBackButton"
          >
            <img class="h-[0.75em] mr-[0.25em]" src="@/assets/chevron-left-solid.svg" alt="">
            {{ setBackTitle }}
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  methods: {
    convertToSlug( string ) {
      return string ? string.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'') : '';
    },
    setToObject(name, levelOne, levelTwo) {
      let to = {
        name: name
      }

      if ( levelOne || levelTwo ) {
        to['params'] = {};
      }

      if ( levelOne !== undefined ) {
        to['name'] += ' Level One';
        to['params']['levelOne'] = this.convertToSlug(levelOne);
      }
      
      if ( levelTwo !== undefined ) {
        to['name'] += ' Level Two';
        to['params']['levelTwo'] = this.convertToSlug(levelTwo);
      }

      return to;
    },
    setBackButton() {
      let routeName = this.$route.name;

      if ( routeName.includes('Level One') ) {
        routeName = routeName.replace(' Level One', '');
      } else if ( routeName.includes('Level Two') ) {
        routeName = routeName.replace('Level Two', 'Level One');
      } else {
        routeName = 'Home';
      }

      return this.$router.push({ name: routeName });
    },
    goToLevelTwo(event) {
      /* Go to the path by adding on the id to the current path */
      this.$router.push({ 
        path: `${this.$route.fullPath}/${this.convertToSlug(event.target.value)}`
      })
    }
  },
  computed: {
    getIsLoading() {
      return this.$store.getters.getIsLoading;
    },
    isHome() {
      return this.$route.matched[0].name === 'Home' ? true : false;
    },
    setMenuTitle() {
      let menuTitle = this.$store.getters.getRouteObject.meta.menuLevel === 'home' ? 'Find partners by region:' : 'Select your location:';
      return menuTitle;
    },
    getMenuItems() {
      return this.$store.getters.getMenuItems;
    },
    setBackTitle() {
      let route     = this.$route;
      let backTitle = 'Back to World Regions';

      if ( route.params.levelOne && !route.params.levelTwo ) {
        backTitle = `Back to ${route.name.replace(' Level One', '')}`;
      } else if ( route.params.levelTwo ) {
        backTitle = `Back to ${route.params.levelOne.replace('-', ' ')}`;
      }

      return backTitle;
    }
  }
}
</script>

<style scoped>
  a.router-link-active {
    @apply text-white bg-primary pointer-events-none lg:before:border-white;
  }
</style>