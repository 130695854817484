<template>
  <header>
    <div class="flex items-center h-16 bg-white px-[0.9375rem] lg:h-auto lg:px-[1.625em] lg:py-[1.25em]">
      <img class="lg:w-[18em]" src="../assets/motorola-logo.svg" alt="Motorola logo" width="263" height="29.36">
    </div>

    <div class="relative flex justify-center items-center bg-primary px-[0.9375rem] md:justify-start lg:h-[155px] lg:px-[1.625em]">
      <h1 class="text-white text-2xl lg:text-[48px] tracking-normal leading-[57px] text-center lg:text-left">Ecosystem Solutions Partners</h1>


      <!-- Background Image -->
      <img class="absolute top-0 right-0 h-full" src="../assets/M-Mask.svg" alt="">
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
}
</script>
