<template>
  <svg viewBox="0 0 170 229" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="MAP_MARKERS"><path d="M0,90.889c0.833,10 3.333,20 7.917,29.583c21.25,52.083 76.666,108.333 76.666,108.333c0,0 55,-55.833 76.667,-107.916c5.417,-11.25 8.333,-24.167 8.333,-36.667l0,-2.917c-2.083,-46.666 -41.666,-82.916 -88.333,-81.25c-45.417,1.667 -80.833,38.75 -81.25,83.75l0,7.084Z" style="fill:#fff;fill-rule:nonzero;"/><path d="M84.583,20.055c35.417,0 64.167,28.75 64.167,64.167c0,35.417 -28.75,64.167 -64.167,64.167c-35.416,-0 -64.166,-28.75 -64.166,-64.167c-0,-35.417 28.75,-64.167 64.166,-64.167Z" :style="`fill:${color};fill-rule:nonzero;`"/><path d="M76.667,45.889l16.666,-0l0,29.166l25,0l0,16.25l-25,0l0,29.167l-16.666,-0l-0,-29.167l-25,0l-0,-16.25l25,0l-0,-29.166Z" style="fill:#fff;fill-rule:nonzero;"/></g></svg>
</template>

<script>
export default {
  name: 'MapPin',
  props: {
    color: String
  }
}
</script>
