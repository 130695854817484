<template>
  <main class="relative flex flex-col justify-center items-center flex-grow bg-grey lg:flex-grow-0">
    <template v-if="getIsLoading">
      <Loading />
    </template>

    <Navbar class="relative z-50 py-12 lg:absolute lg:top-1/2 lg:left-[4%] lg:transform lg:-translate-y-1/2 lg:py-0" :class="{ 'level-has-partners': getPartners && getIsMobile }" />

    <div v-if="!getIsLoading" v-on="{ click: getShowGrid && !getIsMobile ? getCoordinates : null }" class="map w-full" :class="{ 'relative flex-grow': getPartners }">
      <div v-if="getShowGrid && !getIsMobile" class="grid absolute top-0 left-0 z-40 w-full h-full">
        <MapGrid />
        <MapPin class="grid__pin absolute bottom-[0.5vw] left-[50vw] block w-[2em] transform -translate-x-1/2 -translate-y-full opacity-60 pointer-events-none" />
      </div>

      <!-- Desktop map -->
      <div v-if="!getIsMobile" class="absolute inset-0 opacity-75 lg:static lg:inset-[unset] lg:opacity-100">
        <transition
          :css="false"
          @before-enter="mapBeforeEnter"
          @enter="mapEnter"
          appear
        >
          <component :is="getMapComponent" class="relative inset-[unset] w-auto h-auto translate-x-0"></component>
        </transition>
      </div>

      <!-- Mobile map -->
      <div v-if="getIsMobile" class="absolute inset-0 opacity-75 overflow-hidden lg:static lg:inset-[unset] lg:opacity-100">
        <transition
          :css="false"
          @before-enter="mapBeforeEnter"
          @enter="mapEnter"
          appear
        >
          <div class="absolute inset-0 left-1/2 w-max h-full transform -translate-x-1/2 opacity-0 lg:relative lg:inset-[unset] lg:w-auto lg:h-auto lg:translate-x-0">
            <component :is="getMapComponent"></component>
          </div>
        </transition>
      </div>

      <div v-if="getPartners" class="flex-grow pointer-events-none">
        <div class="absolute inset-0 flex flex-col justify-center p-4 lg:static lg:inset-[unset] lg:p-0">
          <ul class="overflow-scroll space-y-3 pr-6 lg:space-y-0 lg:overflow-auto lg:pr-0">
            <transition-group
              :css="false"
              @before-enter="partnerBeforeEnter"
              @enter="partnerEnter"
              appear
            >
              <PartnerItem v-for="(partner, index) in getPartners" :key="partner.name + index" :id="index" :partner="partner" :data-index="index" />
            </transition-group>
          </ul>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import gsap from 'gsap'
import Loading from './Loading'
import Navbar from './Navbar'
import PartnerItem from './partner/PartnerItem'
import MapGrid from './maps/MapGrid'
import MapPin from './partner/MapPin'

export default {
  name: 'Main',
  components: {
    Loading,
    Navbar,
    PartnerItem,
    MapGrid,
    MapPin
  },
  props: {
    levelOne: String,
    levelTwo: String
  },
  methods: {
    getCoordinates(event) {
      const grid = document.querySelector('.grid');
      
      if ( event.target == grid ) {
        /* Determines the amount the map grid is divided into (More accuarte the higher the number) */
        const density = 10000;
        /* Make an array of X axis numbers using the density as the length */
        const xAxis = Array.from({length: density}, (_, i) => i + 1);
        /* Make an array of Y axis numbers using the density and the rough ratio of the map to reduce the length of the y axis (to make the invisible grid sizes as 1:1 as possible) */
        const yAxis = Array.from({length: (density / 20) * 7.4}, (_, i) => i + 1);
        /* Get the bounding box details of the map */
        const rect = event.target.getBoundingClientRect();
        /* Get the mouse x position relative to the map */
        const mouseX = event.clientX - rect.left;
        /* Get the mouse y position relative to the map */
        const mouseY = event.clientY - rect.top;
        /* Calculate the the cell size of the X and Y axes */
        const rectWidthCell = rect.width / xAxis.length;
        const rectHeightCell = rect.height / yAxis.length;

        xAxis.forEach((item, index) => {
          /* Get the current X axis value and calculate it as a percentage */
          let axisPercentage = (100 / xAxis.length) * item;

          /* Set the current X axis to an object */
          xAxis[index] = {
            /* Make sure all values are to 2 decimal places */
            value: axisPercentage.toFixed(2),
            /* Calculate the min/max range by using the current index and multiplying it by the X cell size */
            min: index * rectWidthCell,
            max: (index + 1) * rectWidthCell
          }
        });

        yAxis.forEach((item, index) => {
          /* Get the current Y axis value and calculate it as a percentage */
          let axisPercentage = (100 / yAxis.length) * item;

          /* Set the current Y axis to an object */
          yAxis[index] = {
            /* Make sure all values are to 2 decimal places */
            value: axisPercentage.toFixed(2),
            /* Calculate the min/max range by using the current index and multiplying it by the Y cell size */
            min: index * rectHeightCell,
            max: (index + 1) * rectHeightCell
          }
        });

        /* Return the item which has the mouseX value between its min & max range */
        let x = xAxis.filter(item => {
          if ( mouseX > item.min && mouseX < item.max ) {
            return item;
          }
        });
      
        /* Return the item which has the mouseY value between its min & max range */
        let y = yAxis.filter(item => {
          if ( mouseY > item.min && mouseY < item.max ) {
            return item;
          }
        });

        /* Position the grid pin with the x and y values (as percentages) to give the user reference */
        let gridPin = document.querySelector('.grid__pin');
        gridPin.style.left = x[0].value + '%';
        gridPin.style.top = y[0].value + '%';

        /* Set the grid x and y states to the given values */
        this.$store.dispatch('setGridPinX', x[0].value);
        this.$store.dispatch('setGridPinY', y[0].value);
      }
    },
    partnerBeforeEnter(el) {
      el.style.opacity = 0;
      el.style.transformOrigin = 'left top';
      el.style.transform = 'scale(0)';
    },
    partnerEnter(el, done) {
      gsap.to(el, {
        duration: 0.5,
        opacity: 1,
        x: '-50%',
        y: '-100%',
        scale: 1,
        delay: (el.dataset.index * 0.2) + 0.2, // Add extra delay to account for the map transition
        ease: 'back.out(5)',
        onComplete: () => {
          done
          /* Add pulse class to pin after animation has complete */
          el.querySelector('.mapPin__toggle').classList.add('mapPin--pulse');
        }
      });
    },
    mapBeforeEnter(el) {
      el.style.opacity = 0;
    },
    mapEnter(el, done) {
      gsap.to(el, { 
        duration: 0.5,
        opacity: 1,
        onComplete: done
      });
    }
  },
  computed: {
    getIsLoading() {
      return this.$store.getters.getIsLoading;
    },
    getIsMobile() {
      return this.$store.getters.getIsMobile;
    },
    isHome() {
      return this.$route.matched[0].name === 'Home' ? true : false;
    },
    getMapComponent() {
      let mapName = this.$store.getters.getRouteNameAsSlug;

      if ( this.levelOne !== undefined && this.levelTwo === undefined) {
        mapName = this.levelOne;
      } else if ( this.levelTwo !== undefined ) {
        mapName = this.levelOne + '-' + this.levelTwo;
      }

      return mapName;
    },
    getPartners() {
      return this.$store.getters.getPartners;
    },
    getShowGrid() {
      return this.$store.getters.getShowGrid;
    }
  }
}
</script>

<style>
  .level-has-partners {
    @apply w-full bg-black;
    padding: 0 !important;
  }

  .level-has-partners > h2 {
    @apply hidden;
  }

  .level-has-partners nav ul {
    @apply flex flex-col space-y-0;
  }
  
  .level-has-partners a.router-link-active {
    @apply text-left w-full bg-primary rounded-none px-4 py-3 pointer-events-none;
  }

  .level-has-partners a:not(.router-link-active),
  .level-has-partners .levelOne__select {
    @apply hidden;
  }

  .level-has-partners li.back {
    @apply flex items-center w-full px-4 py-3;
  }
 
  .level-has-partners li.back > span {
    @apply block;
  }
  
  .level-has-partners li.back button {
    @apply flex items-center text-14 w-auto ml-auto;
  }

  .level-has-partners + .map li {
    top: 0 !important;
    left: 0 !important;
    transform: unset !important;
  }

  .grid__pin path {
    fill: black !important;
  }
  
  .map::before {
    @apply block absolute left-0 top-0 bottom-0 z-[5] opacity-80 pointer-events-none;
    content: '';
    width: 100%;
    background: linear-gradient(
      to right,
      hsl(0, 0%, 5%) 0%,
      hsla(0, 0%, 5%, 0.738) 19%,
      hsla(0, 0%, 5%, 0.541) 34%,
      hsla(0, 0%, 5%, 0.382) 47%,
      hsla(0, 0%, 5%, 0.278) 56.5%,
      hsla(0, 0%, 5%, 0.194) 65%,
      hsla(0, 0%, 5%, 0.126) 73%,
      hsla(0, 0%, 5%, 0.075) 80.2%,
      hsla(0, 0%, 5%, 0.042) 86.1%,
      hsla(0, 0%, 5%, 0.021) 91%,
      hsla(0, 0%, 5%, 0.008) 95.2%,
      hsla(0, 0%, 5%, 0.002) 98.2%,
      hsla(0, 0%, 5%, 0) 100%
    );
  }

  @screen lg {
    .map::before {
      width: 40%;
    }
  }
</style>