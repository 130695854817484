<template>
  <div class="absolute bottom-0 left-1/2 flex transform -translate-x-1/2 rounded-t-[0.35em] overflow-hidden">
    <div class="flex items-center font-bold text-[1.5em] text-white bg-black backdrop-blur-sm">
      <div class="leading-none px-[0.75em] py-[0.5em]">Map Coordinates</div>
      <div class="flex font-bold text-[0.875em] space-x-[1em] px-[0.75em] py-[0.5em]">
        <div class="leading-none">PinX: {{ this.$store.getters.getGridPinX }}</div>
        <div class="leading-none">PinY: {{ this.$store.getters.getGridPinY }}</div>
      </div>
    </div>
    <button @click="exitGridMode" class="font-bold text-white uppercase bg-red-600 px-[1.5em]">Exit Grid mode</button>
  </div>
</template>

<script>
export default {
  name: 'MapGrid',
  methods: {
    exitGridMode() {
      this.$store.dispatch('setShowGrid', false);
    }
  }
}
</script>

<style>

</style>
