import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Continent from '../components/levels/Continent.vue'
import NestedView from '../components/utility/NestedView.vue'
import LevelOne from '../components/levels/LevelOne.vue'
import LevelTwo from '../components/levels/LevelTwo.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      menuLevel: 'home'
    }
  }
]

const continents = [
  'Africa', 
  'Asia Pacific', 
  'Europe', 
  'Latin America and Caribbean', 
  'Middle East', 
  'North America'
];

continents.forEach(continent => {
  routes.push(
    {
      path: `/${continent.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')}`,
      component: NestedView,
      children: [
        {
          path: '',
          component: Continent,
          name: `${continent}`,
          meta: {
            menuLevel: 'continent'
          }
        },
        {
          path: ':levelOne',
          component: NestedView,
          children: [
            {
              path: '',
              component: LevelOne,
              name: `${continent} Level One`,
              props: true,
              meta: {
                menuLevel: 'level-one',
              }
            },
            {
              path: ':levelTwo',
              component: NestedView,
              children: [
                {
                  path: '',
                  component: LevelTwo,
                  name: `${continent} Level Two`,
                  props: true,
                  meta: {
                    menuLevel: 'level-two'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  )
})

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
